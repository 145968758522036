var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
              [
                _c("mega-card", [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c(
                        "mega-drop-zone",
                        {
                          attrs: { type: ["image/jpeg", "image/png"] },
                          on: { change: _vm.makePreview }
                        },
                        [
                          !_vm.preview
                            ? _c("mega-image", {
                                attrs: { ratio: "1x1", src: "" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.preview
                            ? _c("img", {
                                staticClass: "w-100",
                                class: { "opacity-0": _vm.rendering },
                                staticStyle: { transition: ".1s" },
                                attrs: { alt: "", src: _vm.preview }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("mega-input", {
                        attrs: { label: _vm.$t("video_name") },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "menu-list" }, [
                    _c(
                      "li",
                      { staticClass: "menu-item" },
                      [
                        _c("mega-switch", {
                          staticClass: "w-100 mb-0 bg-success",
                          attrs: {
                            label: _vm.$t("video_show"),
                            checked: _vm.model["is_active"]
                          },
                          on: { click: _vm.toggleActive }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c(
                        "mega-button",
                        {
                          staticClass: "btn w-100 btn-success",
                          attrs: { loading: _vm.loading },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(_vm._s(_vm.$t("model_save")))]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("mega-card", [
                  _c(
                    "div",
                    {
                      staticClass: "card-body",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _c("mega-image", {
                        attrs: {
                          ratio: "1x1",
                          src: _vm.qr_image,
                          "save-as-img": ""
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _vm.model["modifications"] &&
                _vm.model["modifications"].length > 0
                  ? _c(
                      "nav",
                      { staticClass: "nav sm-down:flex-column mb-3" },
                      _vm._l(_vm.model["modifications"], function(modif, i) {
                        return _c(
                          "span",
                          {
                            key: i,
                            staticClass:
                              "btn btn-sm text-left d-block sm-down:w-fill",
                            class: {
                              "btn-primary":
                                _vm.modification.idt_model_modif ===
                                modif.idt_model_modif
                            },
                            on: {
                              click: function($event) {
                                return _vm.openModif(i)
                              }
                            }
                          },
                          [_vm._v(_vm._s(modif.name || "- - -"))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded && !_vm.modification.video
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: { title: "Warning", type: "warning" }
                          },
                          [_vm._v(_vm._s(_vm.$t("video_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("mega-card", { attrs: { title: _vm.$t("video_vis") } }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("ul", { staticClass: "menu-list" }, [
                      _c(
                        "li",
                        { staticClass: "menu-item" },
                        [
                          _c("mega-switch", {
                            staticClass: "w-100 mb-0 bg-success",
                            class: {
                              "text-muted events-disable": !_vm.modification
                                .video
                            },
                            attrs: {
                              label: _vm.$t("model_vis_ios"),
                              checked: _vm.modification["is_ios"]
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeModif(
                                  "is_ios",
                                  !_vm.modification["is_ios"]
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "menu-item" },
                        [
                          _c("mega-switch", {
                            staticClass: "w-100 mb-0 bg-success",
                            class: {
                              "text-muted events-disable": !_vm.modification
                                .video
                            },
                            attrs: {
                              label: _vm.$t("model_vis_android"),
                              checked: _vm.modification["is_android"]
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeModif(
                                  "is_android",
                                  !_vm.modification["is_android"]
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("mega-card", { attrs: { title: _vm.$t("video_src") } }, [
                  _vm.modification.video
                    ? _c(
                        "div",
                        { staticClass: "card-body d-flex flex-column" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "mega-btn-upload",
                                {
                                  staticClass:
                                    "btn w-100 mb-3 btn-primary align-self-end",
                                  attrs: {
                                    loading: _vm.loading,
                                    type: ["video/mp4"]
                                  },
                                  on: { change: _vm.renderFile }
                                },
                                [_vm._v(_vm._s(_vm.$t("video_new")))]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("mega-video", {
                            attrs: {
                              ratio: "16x9",
                              src: _vm.modification.video
                                ? _vm.modification.video.url
                                : ""
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c(
                            "mega-drop-zone",
                            {
                              staticClass: "content-center mt-3 p-5",
                              attrs: { type: ["video/mp4"] },
                              on: { change: _vm.renderFile }
                            },
                            [
                              _c("mega-video", {
                                attrs: {
                                  ratio: "16x9",
                                  src: _vm.modification.video
                                    ? _vm.modification.video.url
                                    : ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]),
                _vm._v(" "),
                _c(
                  "mega-card",
                  {
                    attrs: {
                      title: _vm.$t("video_imgs"),
                      menu: [{ icon: "i-plus", click: _vm.addImage }]
                    }
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "ul",
                        { staticClass: "menu-list" },
                        _vm._l(_vm.ref_images, function(img, i) {
                          return _c(
                            "li",
                            {
                              key: i,
                              staticClass: "menu-item cursor-pointer",
                              on: {
                                click: function($event) {
                                  return _vm.editImage(img["idt_ref_image"])
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("span", { staticClass: "form-label" }, [
                                  _vm._v("ID: " + _vm._s(img.idt_ref_image))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "mr-auto form-label" },
                                  [_vm._v(_vm._s(img.name))]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { width: "40px" } },
                                [
                                  _c("mega-image", {
                                    attrs: {
                                      ratio: "1x1",
                                      small: "",
                                      src: img.url
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("new-image", {
        ref: "new-image",
        attrs: { id: _vm.model_id, mod_id: _vm.modification_id },
        on: { close: _vm.getModel }
      }),
      _vm._v(" "),
      _c("edit-image", { ref: "edit-image", on: { close: _vm.getModel } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }